import React from 'react';

const Loader = ({center}) => {
  const centerStyle = {
    width: "5rem", 
    height: "5rem", 
    marginTop: '50vh'
  }

  const defaultStyle = {
    width: "2.5rem", 
    height: "2.5rem", 
  }

  return (
    <div className='d-flex justify-content-center'>
      <div
        className="spinner-border text-success"
        style={center ? centerStyle : defaultStyle}
        id='loader'
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default Loader;
