import { isLoginCheck } from "./loginReducer";

const INITIALIZED = "app/INITIALIZED"

let initialState = {
  initializing: true
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZED:
      return {
        ...state,
        initializing: false
      }

    default:
      return state;
  }
}

// AC
const initializedAC = () => ({ type: INITIALIZED });

// Thunk
export const initialized = () => async (dispatch) => {
  const promise = dispatch(isLoginCheck());

  promise.then(() => {
    dispatch(initializedAC());
  }).catch(() => {
    dispatch(initializedAC());
  })
}

export default appReducer;